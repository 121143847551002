import { Html, Head, Main, NextScript } from "next/document";
import { useEffect } from "react";
import Script from "next/script";
import ReactGA from "react-ga";
export const initGA = () => {
  ReactGA.initialize("174948987");
  // put your tracking id here // put your tracking id here
};
export const GApageView = (page) => {
  ReactGA.pageview(page);
};

export const linkedin_partner_id = "2306106";
export const GAtiming = (categoryName, variableName, valueNum) => {
  ReactGA.timing({
    category: categoryName,
    variable: variableName,
    value: valueNum,
  });
};

export default function Document() {
  return (
    <Html lang="en">
      <Head>
        {/* <script defer src='../assets/utilitiz/hotjar.js'></script> */}
        <link rel="icon" href="/images/logo.png" />
      </Head>
      <body>
        <Main />
        <NextScript />
        <Script id="hotjar">
          {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2953759,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
        </Script>
      </body>
    </Html>
  );
}
