import { useLottie } from "lottie-react";
import groovyWalkAnimation from "../../assets/404.json"
import { useEffect } from "react";
import { GApageView, initGA, linkedin_partner_id } from "../_document";
import LinkedInTag from 'react-linkedin-insight';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

export default function Custom404() {

  useEffect(() => {
    initGA();
    GApageView("404Page");
    LinkedInTag.init(linkedin_partner_id);
  }, [])
  const options = {
    animationData: groovyWalkAnimation,
    loop: true
  };

  const { View } = useLottie(options);

  return (<>
    <div className="md:w-[70%] w-3/5 m-auto my-16"> {View}</div>

  </>);
}


export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'common'
        
      ])),
    },
  }
}